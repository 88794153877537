/* eslint react/boolean-prop-naming: 0 */
import React, { useEffect, useRef, useState } from 'react';
import { IoPlaySharp, IoPauseSharp } from 'react-icons/io5';

import PropTypes from 'prop-types';
import * as style from './VideoPlayer.module.scss';

// eslint-disable-next-line no-unused-vars
const PlayButton = ({ buttonType, onClick }) => {
  return (
    <div
      className={`${style.playButton} ${
        buttonType === 'pause' ? style.pauseButton : ''
      }`}
      onClick={onClick}
    >
      {buttonType === 'play' ? (
        <IoPlaySharp className={style.playIcon} />
      ) : (
        <IoPauseSharp className={style.pauseIcon} />
      )}
    </div>
  );
};

/**
 * Video Player for self-hosted and external (YouTube, Vimeo, etc.) videos
 */
const VideoPlayer = (props) => {
  const { src, controls, autoplay, loop, muted, playsInline, behavior } = props;

  const [buttonType, setButtonType] = useState('play');
  const [hasControls, setHasControls] = useState(false);

  useEffect(() => {
    if (buttonType === 'pause') {
      setHasControls(controls);
    }
  }, [controls, buttonType]);

  const videoRef = useRef();

  const handlePlayPauseClick = () => {
    if (buttonType === 'play') {
      videoRef?.current?.play();
      setButtonType('pause');
      return;
    }

    videoRef?.current?.pause();
    setButtonType('play');
  };

  return (
    <div>
      <div className={style.playerWrapper}>
        {behavior === 'minimalistic' && (
          <PlayButton buttonType={buttonType} onClick={handlePlayPauseClick} />
        )}
        {behavior === 'full' && buttonType === 'play' && (
          <PlayButton buttonType={buttonType} onClick={handlePlayPauseClick} />
        )}
        <video
          className={style.player}
          src={src}
          // using `|| null` since we need to omit these attributes completely (not pass `false`)
          controls={hasControls || null}
          autoPlay={autoplay || null}
          loop={loop || null}
          muted={muted || null}
          playsInline={playsInline || null}
          ref={videoRef}
        />
      </div>
    </div>
  );
};

VideoPlayer.defaultProps = {
  autoplay: false,
  behavior: 'full',
  controls: true,
  loop: false,
  muted: false,
  playsInline: true,
};

VideoPlayer.propTypes = {
  /** Provide video controls (play, pause, seekbar). */
  controls: PropTypes.bool,
  /** Autoplay the video */
  autoplay: PropTypes.bool,
  /** Loop the video */
  loop: PropTypes.bool,
  /** Mute the video */
  muted: PropTypes.bool,
  /** Play video inline on mobile devices (do not open fullscreen) */
  playsInline: PropTypes.bool,
  /** Video url (self-hosted or external) */
  src: PropTypes.string.isRequired,
  /** Player behavior */
  behavior: PropTypes.oneOf(['minimalistic', 'full']),
};

export default VideoPlayer;
