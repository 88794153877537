import React, { useEffect, useMemo, useState } from 'react';
import { graphql } from 'gatsby';
import { Media } from 'gatsby-plugin-fresnel';

import '../../styles/fonts.css';
import { useTranslation } from 'react-i18next';
import MainLayout from '../../components/MainLayout';
import * as style from './index.module.scss';
import AnnouncementItem from '../../components/AnnouncementItem';
import LargePost from '../../components/LargePost';
import SmallPost from '../../components/SmallPost';
import HomePageSEO from '../../components/SEO/HomePageSEO';
import { AUTHOR_URL, CATEGORIES_URL } from '../../utilities/urlConstants';
import { excludeTagSlugs } from '../../utilities/sharedUtils';
import { pathJoinSafeSlashes } from '../../utilities/pathJoinSafe';
import ArticleButton from '../../components/ArticleMarkup/ArticleButton';
import VideoPlayer from '../../components/VideoPlayer';
import { postToAlgoliaRecord } from '../../utilities/postToAlgoliaRecord';
import useOnNewActiveLanguage from '../../hooks/useOnNewActiveLanguage';

export const query = graphql`
  fragment postData on WpPost {
    id
    categories {
      nodes {
        name
        slug
        language {
          locale
        }
      }
    }
    dateGmt
    slug
    title
    author {
      node {
        hq_avatar {
          hqAvatar {
            id
            imgixImage {
              gatsbyImageData(
                width: 42
                height: 42
                srcSetMaxWidth: 256
                placeholder: BLURRED
                sizes: "42px"
              )
            }
          }
        }
        name
        slug
      }
    }
    Lead {
      lead
    }
    language {
      locale
    }
    seo {
      readingTime
    }
    tags {
      nodes {
        name
        slug
      }
    }
  }

  query (
    $wpSlug: String!
    $locale: String!
    $tagId: String!
    $topMenuId: String!
    $bottomMenuId: String!
    $footerId: String!
    $socialMenuId: String!
  ) {
    topMenu: wpMenu(id: { eq: $topMenuId }) {
      ...topMenuFields
    }
    bottomMenu: wpMenu(id: { eq: $bottomMenuId }) {
      ...otherMenuFields
    }
    footer: wpMenu(id: { eq: $footerId }) {
      ...otherMenuFields
    }
    socialMenu: wpMenu(id: { eq: $socialMenuId }) {
      ...otherMenuFields
    }
    largePostsRaw: allWpPost(
      sort: { fields: dateGmt, order: DESC }
      limit: 1
      filter: {
        tags: {
          nodes: {
            elemMatch: {
              id: { eq: $tagId }
              slug: { nin: ["dope-sustainability", "montec-sustainability"] }
            }
          }
        }
        status: { eq: "publish" }
      }
    ) {
      nodes {
        ...postData
        featuredImage {
          node {
            altText
            title
            slug
            imgixImage {
              gatsbyImageData: gatsbyImageData(
                aspectRatio: 1
                width: 768
                placeholder: BLURRED
                sizes: "(max-width: 768px) 420px, (max-width: 1366px) 640px, 768px"
                imgixParams: { fit: "crop", crop: "faces,entropy" }
              )
              gatsbyImageDataTabletOnly: gatsbyImageData(
                width: 640
                height: 400
                placeholder: BLURRED
                sizes: "(max-width: 768px) 420px, (max-width: 1366px) 640px, 768px"
                imgixParams: { fit: "crop", crop: "faces,entropy" }
              )
            }
          }
        }
      }
    }
    smallPostsRaw: allWpPost(
      sort: { fields: dateGmt, order: DESC }
      limit: 4
      filter: {
        tags: {
          nodes: {
            elemMatch: {
              id: { eq: $tagId }
              slug: { nin: ["dope-sustainability", "montec-sustainability"] }
            }
          }
        }
        status: { eq: "publish" }
      }
      skip: 1
    ) {
      nodes {
        ...postData
        featuredImage {
          node {
            altText
            title
            slug
            imgixImage {
              gatsbyImageData(
                width: 600
                placeholder: BLURRED
                sizes: "(max-width: 768px) 210px, (max-width: 1366px) 250px, 600px"
                imgixParams: { auto: "format", q: 70, usm: 15 }
              )
            }
          }
        }
      }
    }
    latestPosts: allWpPost(
      sort: { fields: dateGmt, order: DESC }
      limit: 20
      filter: {
        #tags: { nodes: { elemMatch: { id: { ne: $tagId } } } }
        tags: {
          nodes: {
            elemMatch: {
              slug: { nin: ["dope-sustainability", "montec-sustainability"] }
            }
          }
        }
        status: { eq: "publish" }
        language: { locale: { eq: $locale } }
      }
    ) {
      nodes {
        ...postData
        featuredImage {
          node {
            altText
            title
            slug
            imgixImageHomepage: imgixImage {
              gatsbyImageData(
                width: 600
                placeholder: BLURRED
                sizes: "(max-width: 768px) 210px, (max-width: 1366px) 250px, 600px"
                imgixParams: { auto: "format", q: 70, usm: 15 }
              )
            }
          }
        }
      }
    }
    wpPage(slug: { eq: $wpSlug }, language: { locale: { eq: $locale } }) {
      blocks {
        __typename
        saveContent
        ... on WpCoreVideoBlock {
          attributes {
            ... on WpCoreVideoBlockAttributes {
              src
              poster
              muted
              loop
              controls
              autoplay
              playsInline
            }
          }
        }
      }
      HomepageQuote {
        homepageQuote
      }
      HomepageInnerBanner {
        homepageInnerBanner {
          homepageInnerBannerTextLine1
          homepageInnerBannerTextLine2
          homepageInnerBannerButton {
            homepageInnerBannerButtonLink
            homepageInnerBannerButtonText
          }
        }
      }
      seo {
        canonical
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphDescription
        opengraphModifiedTime
        opengraphPublishedTime
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          sourceUrl
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

const initialPage = 0;

const Index = (props) => {
  const { data, pageContext } = props;
  const {
    largePostsRaw,
    smallPostsRaw,
    latestPosts: latestPostsInitial,
    wpPage,
    site,
    topMenu,
    bottomMenu,
    footer,
    socialMenu,
  } = data;

  const { t } = useTranslation();

  const smallPosts = smallPostsRaw?.nodes.filter(
    (post) => !post.tags.nodes.some((tag) => excludeTagSlugs.includes(tag.slug))
  );
  const largePosts = largePostsRaw?.nodes.filter(
    (post) => !post.tags.nodes.some((tag) => excludeTagSlugs.includes(tag.slug))
  );

  const evenSmallPosts = smallPosts?.filter((el, index) => index % 2 === 0);
  const oddSmallPosts = smallPosts.filter((el, index) => index % 2);

  const [latestPosts, setLatestPosts] = useState([]);
  const [latestPostsPage, setLatestPostsPage] = useState(initialPage);
  const [latestPostsMaxPages, setLatestPostsMaxPages] = useState(100);
  const [isLoadMoreVisible, setIsLoadMoreVisible] = useState(true);

  /**
   * Load initial Latest Posts using Gatsby
   */

  const latestPostsInitialTransformed = useMemo(
    () =>
      latestPostsInitial?.nodes
        ?.map(postToAlgoliaRecord)
        .filter(
          (post) =>
            !post.wpTags.some((tag) => excludeTagSlugs.includes(tag.slug))
        ),
    [latestPostsInitial]
  );

  const performAlgolia = (locale, hitsPerPage, page) => {
    import('algoliasearch/lite').then(({ default: algoliasearch }) => {
      const searchClient = algoliasearch(
        process.env.GATSBY_ALGOLIA_APP_ID,
        process.env.GATSBY_ALGOLIA_SEARCH_KEY
      );

      const searchIndex = searchClient.initIndex(
        process.env.GATSBY_ALGOLIA_POSTS_INDEX_NAME
      );

      searchIndex
        .search('', {
          filters: `languageLocale:${locale}`,
          hitsPerPage,
          page,
        })
        .then(({ hits, nbPages }) => {
          // Out of pages
          if (page + 1 >= latestPostsMaxPages) {
            setIsLoadMoreVisible(false);
            return;
          }

          // Display more pages
          hits = hits
            .filter(
              (post) =>
                !post.wpTags.some((tag) => excludeTagSlugs.includes(tag.slug))
            )
            .filter((post) =>
              post?.categories?.some(
                (category) => category.slug !== 'sustainability'
              )
            );

          setLatestPosts((prevState) => {
            return [...prevState, ...hits];
          });
          setLatestPostsMaxPages(nbPages);
        });
    });
  };

  const loadMorePosts = () => {
    setLatestPostsPage(latestPostsPage + 1);
  };

  // On page change, perform Algolia search
  useEffect(() => {
    if (initialPage === latestPostsPage) return;
    performAlgolia(pageContext.locale, 20, latestPostsPage);
  }, [latestPostsPage]);

  const evenLatestPostsInitialTransformed =
    latestPostsInitialTransformed?.filter((el, index) => index % 2 === 0);
  const oddLatestPostsInitialTransformed =
    latestPostsInitialTransformed?.filter((el, index) => index % 2);

  const evenLatestPosts = latestPosts?.filter((el, index) => index % 2 === 0);
  const oddLatestPosts = latestPosts?.filter((el, index) => index % 2);

  const { seo, blocks, HomepageInnerBanner, HomepageQuote } = wpPage;

  const videoBlock = blocks.find(
    (block) => block.__typename === 'WpCoreVideoBlock'
  );
  const videoBlockAttributes = videoBlock?.attributes;

  /**
   * Redirect to the correct language page
   */

  const setNewActiveLanguage = useOnNewActiveLanguage(pageContext?.locale);

  const getAnnouncementItem = (innerBanner) => {
    const { homepageInnerBanner } = innerBanner;
    const {
      homepageInnerBannerTextLine1: line1,
      homepageInnerBannerTextLine2: line2,
      homepageInnerBannerButton: button,
    } = homepageInnerBanner;

    if (!line2) return;

    return (
      <AnnouncementItem
        superScript={line1}
        title={line2}
        buttonText={button?.homepageInnerBannerButtonText}
        buttonLink={button?.homepageInnerBannerButtonLink}
        buttonLinkTarget="_blank"
      />
    );
  };

  const formSmallPost = (smallPost) => {
    if (!smallPost) return;

    const featuredImg = smallPost?.featuredImage?.node;
    const category = smallPost?.categories?.nodes?.[0];
    const author = smallPost?.author?.node;
    const tag = smallPost?.tags?.nodes?.[0]?.name ?? '';

    return (
      <SmallPost
        key={smallPost?.id}
        imgData={featuredImg?.imgixImage?.gatsbyImageData}
        imgAlt={featuredImg?.altText || featuredImg?.slug}
        imgHoverTitle={featuredImg?.title || featuredImg?.slug}
        category={category?.name}
        categoryLink={pathJoinSafeSlashes(CATEGORIES_URL, category?.slug)}
        title={smallPost?.title}
        authorName={author?.name}
        authorSlug={pathJoinSafeSlashes(AUTHOR_URL, author?.slug)}
        authorImgData={author?.hq_avatar?.hqAvatar?.imgixImage?.gatsbyImageData}
        datePostedGmt={smallPost?.dateGmt}
        slug={pathJoinSafeSlashes(smallPost?.slug)}
        timeToRead={smallPost?.seo?.readingTime}
        tag={tag}
      />
    );
  };

  const formSmallPostAlgolia = (algoliaPost) => {
    const featuredImg = algoliaPost?.featuredImage?.node;
    const category = algoliaPost?.categories?.[0];
    const tag = algoliaPost?.wpTags?.[0]?.name ?? '';

    return (
      <SmallPost
        key={algoliaPost?.id}
        imgData={featuredImg?.imgixImageHomepage?.gatsbyImageData}
        imgAlt={featuredImg?.altText || featuredImg?.slug}
        imgHoverTitle={featuredImg?.title || featuredImg?.slug}
        category={category?.name}
        categoryLink={pathJoinSafeSlashes(CATEGORIES_URL, category?.slug)}
        title={algoliaPost?.title}
        authorName={algoliaPost?.authorName}
        authorSlug={pathJoinSafeSlashes(AUTHOR_URL, algoliaPost?.authorSlug)}
        authorImgData={
          algoliaPost?.authorHQAvatar?.hqAvatar?.imgixImage?.gatsbyImageData
        }
        datePostedGmt={algoliaPost?.dateGmt}
        slug={pathJoinSafeSlashes(algoliaPost?.slug)}
        timeToRead={algoliaPost?.readTime}
        tag={tag}
      />
    );
  };

  return (
    <MainLayout
      setNewActiveLanguage={setNewActiveLanguage}
      topMenu={topMenu}
      bottomMenu={bottomMenu}
      footer={footer}
      socialMenu={socialMenu}
    >
      <HomePageSEO
        seo={seo}
        locale={pageContext.locale}
        siteUrl={site?.siteMetadata?.siteUrl}
      />
      <div className={style.pageWrapper}>
        {largePosts?.map((largePost) => {
          const featuredImage = largePost?.featuredImage?.node;
          const category = largePost?.categories?.nodes?.[0];
          const author = largePost?.author?.node;
          const tag = largePost?.tags?.nodes?.[0]?.name ?? '';

          return (
            <LargePost
              key={largePost?.id}
              imgData={featuredImage?.imgixImage?.gatsbyImageData}
              imgDataTabletOnly={
                featuredImage?.imgixImage?.gatsbyImageDataTabletOnly
              }
              imgAlt={featuredImage?.altText || featuredImage?.slug}
              imgHoverTitle={featuredImage?.title || featuredImage?.slug}
              category={category?.name}
              categoryLink={pathJoinSafeSlashes(CATEGORIES_URL, category?.slug)}
              title={largePost?.title}
              lead={largePost?.Lead?.lead}
              authorImgData={
                author?.hq_avatar?.hqAvatar?.imgixImage?.gatsbyImageData
              }
              authorName={author?.name}
              authorSlug={pathJoinSafeSlashes(AUTHOR_URL, author?.slug)}
              datePostedGmt={largePost?.dateGmt}
              slug={pathJoinSafeSlashes(largePost?.slug)}
              timeToRead={largePost?.seo?.readingTime}
              tag={tag}
            />
          );
        })}

        {/* Featured Posts section */}

        <h2 className={style.homeSectionHeading}>{t('homepage.featured')}</h2>
        <Media lessThan="tablet">
          <div className={style.postDeck}>
            {smallPosts?.nodes?.map((smallPost) => formSmallPost(smallPost))}
          </div>
        </Media>
        <Media greaterThanOrEqual="tablet">
          <div className={style.postDeckDesktop}>
            <div
              className={`${style.postDeckColumn} ${style.postDeckColumnLeft}`}
            >
              {evenSmallPosts?.map((smallPost) => formSmallPost(smallPost))}
            </div>
            <div className={style.postDeckSeparator}>
              <div className={style.postDeckSeparatorInner} />
            </div>
            <div
              className={`${style.postDeckColumn} ${style.postDeckColumnRight}`}
            >
              {formSmallPost(oddSmallPosts?.[0])}
              {getAnnouncementItem(HomepageInnerBanner)}
              {formSmallPost(oddSmallPosts?.[1])}
            </div>
          </div>
        </Media>
        <Media lessThan="tablet">
          {getAnnouncementItem(HomepageInnerBanner)}
        </Media>

        {/* Latest posts section */}

        <h2 className={style.homeSectionHeading}>
          {t('homepage.latestPosts')}
        </h2>
        <Media lessThan="tablet">
          <div className={style.postDeck}>
            {latestPostsInitialTransformed?.map((smallPost) =>
              formSmallPostAlgolia(smallPost)
            )}
            {latestPosts?.map((smallPost) => formSmallPostAlgolia(smallPost))}
          </div>
        </Media>
        <Media greaterThanOrEqual="tablet">
          <div className={style.postDeckDesktop}>
            <div
              className={`${style.postDeckColumn} ${style.postDeckColumnLeft}`}
            >
              {evenLatestPostsInitialTransformed?.map((smallPost) =>
                formSmallPostAlgolia(smallPost)
              )}
              {evenLatestPosts?.map((smallPost) =>
                formSmallPostAlgolia(smallPost)
              )}
            </div>
            <div className={style.postDeckSeparator}>
              <div className={style.postDeckSeparatorInner} />
            </div>
            <div
              className={`${style.postDeckColumn} ${style.postDeckColumnRight}`}
            >
              {oddLatestPostsInitialTransformed?.map((smallPost) =>
                formSmallPostAlgolia(smallPost)
              )}
              {oddLatestPosts?.map((smallPost) =>
                formSmallPostAlgolia(smallPost)
              )}
            </div>
          </div>
        </Media>
        {isLoadMoreVisible && (
          <ArticleButton
            text="LOAD MORE"
            variant="secondary"
            className={style.buttonLoadMore}
            onClick={() => {
              loadMorePosts();
            }}
          />
        )}

        {/* Quote */}

        {HomepageQuote?.homepageQuote && (
          <div className={style.quotesDeckWrapper}>
            <div className={style.quotesDeck}>
              <div className={style.verticalLine} />
              <div className={style.quote}>{HomepageQuote?.homepageQuote}</div>
            </div>
          </div>
        )}
        {/* A good video for testing YouTube: "https://www.youtube.com/watch?v=Sr29sLyUh3c" */}
        {videoBlock && (
          <div className={style.videosDeck}>
            <VideoPlayer
              behavior="minimalistic"
              autoplay={videoBlockAttributes?.autoplay}
              loop={videoBlockAttributes?.loop}
              controls={false}
              src={videoBlockAttributes?.src}
              muted={videoBlockAttributes?.muted}
              playsInline={videoBlockAttributes?.playsInline}
            />
          </div>
        )}
      </div>
    </MainLayout>
  );
};

export default Index;
