// extracted by mini-css-extract-plugin
export var pageWrapper = "index-module--pageWrapper--17aSe";
export var homeSectionHeading = "index-module--homeSectionHeading--3jREq";
export var postDeck = "index-module--postDeck--1uEFi";
export var postDeckDesktop = "index-module--postDeckDesktop--3dyh9";
export var postDeckColumn = "index-module--postDeckColumn--20-pR";
export var postDeckColumnLeft = "index-module--postDeckColumnLeft--3lJjX";
export var postDeckColumnRight = "index-module--postDeckColumnRight--sQD4j";
export var postDeckSeparator = "index-module--postDeckSeparator--3G8H6";
export var postDeckSeparatorInner = "index-module--postDeckSeparatorInner--1TOOl";
export var buttonLoadMore = "index-module--buttonLoadMore--xVrSY";
export var quotesDeckWrapper = "index-module--quotesDeckWrapper--1d0Ve";
export var quotesDeck = "index-module--quotesDeck--29nt2";
export var verticalLine = "index-module--verticalLine--nPNWG";
export var quote = "index-module--quote--rx-Mb";
export var videosDeck = "index-module--videosDeck--3seSd";