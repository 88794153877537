// extracted by mini-css-extract-plugin
export var smallPost = "SmallPost-module--smallPost--1viwC";
export var imgContainer = "SmallPost-module--imgContainer--3mxJD";
export var tag = "SmallPost-module--tag--2SDHm";
export var postBody = "SmallPost-module--postBody--3-4RE";
export var category = "SmallPost-module--category--2wuSv";
export var title = "SmallPost-module--title--vw_1I";
export var authorSection = "SmallPost-module--authorSection--2ED23";
export var authorPic = "SmallPost-module--authorPic--12LCT";
export var authorName = "SmallPost-module--authorName--11Z_3";
export var timeToRead = "SmallPost-module--timeToRead--34Lml";
export var sustainability = "SmallPost-module--sustainability--2PxST";
export var lead = "SmallPost-module--lead--1gJJQ";