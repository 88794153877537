import React from 'react';
import PropTypes from 'prop-types';
import * as style from './AnnouncementItem.module.scss';

/**
 * Announcement banner with a button
 */
const AnnouncementItem = (props) => {
  const { superScript, title, buttonText, buttonLink, buttonLinkTarget } =
    props;

  return (
    <div className={style.announcementItemWrapper}>
      <div className={style.announcementItem}>
        <div>
          <div className={style.superScript}>{superScript}</div>
          <div className={style.title}>{title}</div>
          {buttonLink ? (
            <a
              className={`clearLinkStyle d-block ${style.button}`}
              href={buttonLink}
              target={buttonLinkTarget}
              rel="noopener noreferrer"
            >
              {buttonText}
            </a>
          ) : (
            <div className={style.button}>{buttonText}</div>
          )}
        </div>
      </div>
    </div>
  );
};

AnnouncementItem.defaultProps = {
  buttonLink: '',
  buttonLinkTarget: '_self',
};

AnnouncementItem.propTypes = {
  /** The first line of text in smaller font. Ex: "We're hiring". */
  superScript: PropTypes.string.isRequired,
  /** The second line of text in large font. Ex: "Content Writers". */
  title: PropTypes.string.isRequired,
  /** Text to display on the button. */
  buttonText: PropTypes.string.isRequired,
  /** Link (href) that button leads to. */
  buttonLink: PropTypes.string,
  /**
   * The target to open the link in. "_blank" for a new tab.
   * Omit this attribute or use "_self" for the same page.
   */
  buttonLinkTarget: PropTypes.string,
};

export default AnnouncementItem;
