const clearAllTagsFromString = (plainHtmlString) => {
  if (plainHtmlString) return plainHtmlString.replace(/(<([^>]+)>)/gi, '');
};

const dayjs = require('dayjs');
const intersection = require('lodash/intersection');
const { excludeTagSlugs, includeTagSlugs } = require('./sharedUtils');

const algoliaPostsFilter = (post) => {
  const { tags } = post;

  const tagSlugs = tags?.nodes.map((tag) => tag?.slug);

  // Exclude tags
  if (excludeTagSlugs && intersection(tagSlugs, excludeTagSlugs)?.length)
    return false;

  // Include tags
  if (includeTagSlugs && !intersection(tagSlugs, includeTagSlugs)?.length)
    return false;

  return true;
};

const postToAlgoliaRecord = (post) => {
  const {
    id,
    title,
    Lead,
    categories,
    slug,
    language,
    seo,
    featuredImage,
    dateGmt,
    modifiedGmt,
    author,
    blocks,
    tags,
  } = post;

  const headings = [];

  for (let i = 0; i < blocks?.length; i += 1) {
    const block = blocks[i];

    switch (block.__typename) {
      case 'WpCoreHeadingBlock':
        /**
         * Add heading plain HTML to headings array
         */
        headings.push(clearAllTagsFromString(block?.saveContent));
        break;
      case 'WpAcfNumberedHeadingBlock':
      case 'WpAcfNumberedHeadingWithImageBlock':
        /**
         * Add Numbered Heading and Numbered Heading with Image blocks' heading title to headings
         */
        headings.push(
          clearAllTagsFromString(block?.numberedHeading?.numberedHeadingTitle)
        );
        break;
      default:
        break;
    }
  }

  // Filter out all falsy values
  const filteredHeadings = headings.filter((x) => !!x);

  // Leave only unique headings
  const filteredUniqueHeadings = [...new Set(filteredHeadings)];

  return {
    objectID: id,
    title,
    lead: Lead?.lead,
    slug,
    categories: categories?.nodes,
    languageSlug: language?.slug,
    languageLocale: language?.locale,
    readTime: seo?.readingTime,
    featuredImage,
    dateGmt,
    modifiedGmt,
    timestamp: dayjs(new Date(dateGmt)).unix(),
    authorName: author?.node?.name,
    authorSlug: author?.node?.slug,
    authorHQAvatar: author?.node?.hq_avatar,
    headings: filteredUniqueHeadings,
    wpTags: tags?.nodes,
  };
};

exports.postToAlgoliaRecord = postToAlgoliaRecord;
exports.algoliaPostsFilter = algoliaPostsFilter;
