import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import {
  getLanguageFromField,
  languages,
} from '../../../utilities/sharedUtils';
import { TS } from '../../../utilities/urlConstants';
import { urlJoinSafe } from '../../../utilities';

const HomePageSEO = (props) => {
  const { seo, locale, siteUrl, children } = props;

  // General
  const { title, metaDesc } = seo;

  // Robots
  const { metaRobotsNoindex, metaRobotsNofollow } = seo;

  // Opengraph
  const {
    opengraphTitle,
    opengraphDescription,
    opengraphSiteName,
    opengraphPublisher,
    opengraphModifiedTime,
    opengraphImage,
    // opengraphPublishedTime,
  } = seo;

  // Twitter
  // const { twitterTitle, twitterDescription, twitterImage, readingTime } = seo;

  // Destructuring
  const opengraphImgObj = opengraphImage?.localFile?.childImageSharp?.original;
  // const twitterImgObj = twitterImage?.localFile?.childImageSharp?.original;

  // Static meta tags
  const ogType = 'website';
  const twitterCard = 'summary_large_image';

  // Unused, but might be used later
  // const twitterLabel1 = 'Written by';
  // const twitterLabel2 = 'Estimated reading time';

  return (
    <Helmet
      htmlAttributes={{
        lang: getLanguageFromField('locale', locale).i18nName,
      }}
      meta={[
        { name: 'description', content: metaDesc },
        {
          name: 'robots',
          content: [metaRobotsNoindex, metaRobotsNofollow].join(', '),
        },
        { property: 'og:locale', content: locale },
        // For use later
        // { property: 'og:locale:alternate', content: locale },
        { property: 'og:type', content: ogType },
        { property: 'og:title', content: opengraphTitle },
        { property: 'og:description', content: opengraphDescription },
        { property: 'og:url', content: urlJoinSafe(siteUrl, TS) },
        { property: 'og:site_name', content: opengraphSiteName },
        { property: 'article:publisher', content: opengraphPublisher },
        { property: 'article:modified_time', content: opengraphModifiedTime },
        {
          property: 'og:image',
          content: opengraphImgObj?.src
            ? urlJoinSafe(siteUrl, opengraphImgObj?.src)
            : undefined,
        },
        { property: 'og:image:width', content: opengraphImgObj?.width },
        { property: 'og:image:height', content: opengraphImgObj?.height },
        { name: 'twitter:card', content: twitterCard },

        // Unused, but might be used later
        // { property: 'article:published_time', content: opengraphPublishedTime },
        // { name: 'twitter:title', content: twitterTitle },
        // { name: 'twitter:description', content: twitterDescription },
        // { name: 'twitter:image', content: makeAbsolute(twitterImgObj?.src) },
        // { name: 'twitter:label1', content: twitterLabel1 },
        // { name: 'twitter:data1', content: authorName },
        // { name: 'twitter:label2', content: twitterLabel2 },
        // { name: 'twitter:data2', content: `${readingTime} minutes` },
      ]}
    >
      <title>{title}</title>
      <link rel="canonical" href={urlJoinSafe(siteUrl, TS)} />
      {languages?.map((language) => (
        <link
          key={language.i18nName}
          rel="alternate"
          hrefLang={language.i18nName}
          href={urlJoinSafe(language.languageSiteUrl, TS)}
        />
      ))}
      {children}
    </Helmet>
  );
};

HomePageSEO.propTypes = {
  locale: PropTypes.string.isRequired,
  seo: PropTypes.object.isRequired,
  siteUrl: PropTypes.string.isRequired,
};

export default HomePageSEO;
