import React from 'react';
import PropTypes from 'prop-types';

import * as style from './ArticleButton.module.scss';
import { getRelAttribute } from '../../../utilities';

/** Button used in Article can become <div> or <a> if 'href' is provided */
const ArticleButton = (props) => {
  const { text, variant, href, hrefTarget, isNoFollow, className, onClick } =
    props;

  const getBtnClassFromBtnVariant = (btnVariant) => {
    switch (btnVariant) {
      case 'secondary':
        return style.btnSecondary;
      case 'primary':
      case 'default':
      default:
        return style.btnPrimary;
    }
  };

  return (
    <>
      {href !== '' ? (
        <a
          className={`clearLinkStyle d-block ${
            style.btnBlock
          } ${getBtnClassFromBtnVariant(variant)} ${className}`}
          href={href}
          target={hrefTarget}
          rel={getRelAttribute(hrefTarget, isNoFollow)}
          onClick={onClick}
        >
          {text}
        </a>
      ) : (
        <div
          className={`${style.btnBlock} ${getBtnClassFromBtnVariant(
            variant
          )}  ${className}`}
          onClick={onClick}
        >
          {text}
        </div>
      )}
    </>
  );
};

ArticleButton.defaultProps = {
  className: '',
  href: '',
  hrefTarget: '_self',
  isNoFollow: false,
  onClick: () => {},
  variant: 'primary',
};

ArticleButton.propTypes = {
  /** Text to display on the button. */
  text: PropTypes.string.isRequired,
  /** Link to the destination page (optional). */
  href: PropTypes.string,
  /** Style of a button. Can be 'primary' or 'secondary'. */
  variant: PropTypes.string,
  /**
   * The target to open the link in. "_blank" for a new tab.
   * Omit this attribute or use "_self" for the same page.
   */
  hrefTarget: PropTypes.string,
  /** Make a link have ref='nofollow' attribute */
  isNoFollow: PropTypes.bool,
  /** Extra class name */
  className: PropTypes.string,
  /** OnClick function */
  onClick: PropTypes.func,
};

export default ArticleButton;
