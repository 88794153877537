// extracted by mini-css-extract-plugin
export var largePost = "LargePost-module--largePost--32UB8";
export var image = "LargePost-module--image--1n3d-";
export var imageLink = "LargePost-module--imageLink--1uhbu";
export var tag = "LargePost-module--tag--1w78_";
export var postBody = "LargePost-module--postBody--3JNFf";
export var category = "LargePost-module--category--fv1iS";
export var title = "LargePost-module--title--2Lbz4";
export var lead = "LargePost-module--lead--3PwCx";
export var authorSection = "LargePost-module--authorSection--3QgJV";
export var authorPic = "LargePost-module--authorPic--3yRVS";
export var authorName = "LargePost-module--authorName--1u5oD";
export var timeToRead = "LargePost-module--timeToRead--1HLNT";
export var readMoreButton = "LargePost-module--readMoreButton--a0QCC";
export var sustainability = "LargePost-module--sustainability--9nQ0R";